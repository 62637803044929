import React from "react";
import PropTypes from "prop-types";

import RaisedButton from "material-ui/RaisedButton";
import IconButton from "material-ui/IconButton/IconButton";
import ContentCopy from "material-ui/svg-icons/content/content-copy";
import CircularProgress from "material-ui/CircularProgress";
import ErrorIcon from "material-ui/svg-icons/alert/error";

import AggregatedScorecardStore from "../../../storage/AggregatedScorecardStore";

export default class ScorecardHeader extends React.Component {
  static propTypes = {
    openScorecard: PropTypes.func.isRequired,
    showViewScorecardButton: PropTypes.bool.isRequired,
    newCallClickHandler: PropTypes.func.isRequired,
    lastCall: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCopying: false,
      hasError: false,
    };
    this.aggregatedScorecardStore = new AggregatedScorecardStore();
  }

  copyToClipboard = () => {
    const { organizationId } = this.props;
    this.setState({ isCopying: true, hasError: false });

    this.aggregatedScorecardStore
      .markdown(organizationId)
      .then((response) => {
        if (response && response.body && response.body.markdown) {
          return navigator.clipboard.writeText(response.body.markdown);
        }
        this.setState({ hasError: true });
        return Promise.resolve();
      })
      .catch((error) => {
        console.error("Error copying markdown:", error);
        this.setState({ hasError: true });
      })
      .finally(() => {
        this.setState({ isCopying: false });
      });
  };

  resetError = () => {
    this.setState({ hasError: false });
  };

  render() {
    const {
      openScorecard,
      showViewScorecardButton,
      newCallClickHandler,
      lastCall,
    } = this.props;

    return (
      <div className="scorecard-header-content">
        <div className="spacer" />
        {this.state.hasError ? (
          <IconButton
            onClick={this.resetError}
            style={{
              width: 24,
              height: 24,
              padding: 0,
              marginRight: 8,
            }}
            iconStyle={{
              width: 20,
              height: 20,
              color: "#E53935",
            }}
          >
            <ErrorIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={this.copyToClipboard}
            disabled={this.state.isCopying}
            style={{
              width: 24,
              height: 24,
              padding: 0,
              marginRight: 8,
            }}
            iconStyle={{
              width: 20,
              height: 20,
            }}
          >
            {this.state.isCopying ? (
              <CircularProgress size={16} thickness={2} />
            ) : (
              <ContentCopy />
            )}
          </IconButton>
        )}
        <RaisedButton
          className="scorecard-button new-call"
          label="NEW CALL"
          backgroundColor="#ffffff"
          labelColor="#000000"
          style={{
            marginRight: showViewScorecardButton ? "12px" : "0",
          }}
          onClick={() => newCallClickHandler()}
        />
        <If condition={showViewScorecardButton}>
          <RaisedButton
            className="scorecard-button view-scorecard"
            label="VIEW SCORECARD"
            backgroundColor="#5dab49"
            labelColor="#ffffff"
            onClick={() => openScorecard("view", lastCall.id)}
          />
        </If>
      </div>
    );
  }
}
